import ChargebeeContext from '../../utils/account/ChargebeeContext'
import React from 'react'

const withChargebee = Component =>
  props => (
    <ChargebeeContext.Consumer>
      {context => <Component {...context} {...props} />}
    </ChargebeeContext.Consumer>
  )

export default withChargebee
