import React from 'react'

// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!
export const DownloadFormContext = React.createContext({
  url: '',
  method: 'offline',
  target: 'allpages',
  timestamp: undefined,
  handleUrlChange: () => {},
  handleMethodChange: () => {},
  handleTargetChange: () => {},
  handleTimestampChange: () => {}
})
