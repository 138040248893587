import './DownloadForm.css'

import { isGoogleUrl, isUrl, shouldDownloadFirstPage } from '../../utils/commons/helpers'

import { DownloadFormContext } from './DownloadFormContext'
import React from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import empty from 'is-empty'
import { navigate } from '@reach/router'
import normalizeUrl from 'normalize-url'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  form: {
    height: 80,
    [theme.breakpoints.down('sm')]: {
      height: 160
    }
  }
})

ValidatorForm.addValidationRule('isUrl', isUrl)

class DownloadForm extends React.Component {
  constructor (props) {
    super(props)

    this.handleUrlChange = (url) => {
      this.setState(state => ({
        url: url
      }))

      if (!empty(this.state.timestamp)) {
        this.setState(state => ({
          timestamp: 'latest'
        }))
      }
    }

    this.handleMethodChange = (method) => {
      this.setState(state => ({
        method: method
      }))
    }

    this.handleTargetChange = (target) => {
      this.setState(state => ({
        target: target
      }))
    }

    this.handleTimestampChange = (timestamp) => {
      this.setState(state => ({
        timestamp: timestamp
      }))
    }

    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      url: this.props.url || '',
      method: this.props.method || 'offline',
      target: this.props.target || 'allpages',
      timestamp: this.props.timestamp || undefined,
      handleUrlChange: this.handleUrlChange,
      handleMethodChange: this.handleMethodChange,
      handleTargetChange: this.handleTargetChange,
      handleTimestampChange: this.handleTimestampChange
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit () {
    let target = this.state.target
    let normalizationOptions = { stripWWW: false, removeTrailingSlash: false }

    if (empty(this.props.url) && (shouldDownloadFirstPage(this.state.url) || isGoogleUrl(this.state.url))) {
      normalizationOptions.defaultProtocol = 'https:'
      target = 'firstpage'
    }

    const url = normalizeUrl(this.state.url, normalizationOptions)

    if (empty(this.state.timestamp)) {
      navigate(`/preview-download/${this.state.method}/${target}/${url}`)
    } else {
      navigate(`/wayback-machine-downloader/preview-download/${this.state.method}/${target}/${this.state.timestamp}/${this.props.page}/${url}`)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.url !== this.props.url || prevProps.method !== this.props.method || prevProps.target !== this.props.target || prevProps.timestamp !== this.props.timestamp) {
      this.setState(state => ({
        url: this.props.url,
        method: this.props.method,
        target: this.props.target,
        timestamp: this.props.timestamp
      }))
    }
  }

  render () {
    const { classes, children } = this.props

    return (
      <ValidatorForm
        ref='form'
        onSubmit={this.handleSubmit}
        className={classes.form}
      >
        <DownloadFormContext.Provider value={this.state}>
          {children}
        </DownloadFormContext.Provider>
      </ValidatorForm>
    )
  }
}

export default withStyles(styles)(DownloadForm)
