import { DownloadFormContext } from './DownloadFormContext'
import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  textInput: {
    background: 'white'
  },
  textInputWithoutButton: {
    margin: '12px 0',
    minWidth: '100%'
  },
  textInputWithButton: {
    margin: '12px 4px',
    minWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '90%'
    }
  }
})

const DownloadUrlInput = props => {
  const { classes, autoFocus, withButton, wayback } = props
  const label = props.label || (wayback ? 'Download more than 339 billion web pages saved over time' : 'Download more than 339 billion web pages')
  const index = props.index || 0

  let className = `${classes.textInput} `
  if (withButton) {
    className += classes.textInputWithButton
  } else {
    className += classes.textInputWithoutButton
  }

  return (
    <DownloadFormContext.Consumer>
      {({ url, handleUrlChange }) => (
        <TextValidator
          autoFocus={autoFocus}
          label={label}
          placeholder='E.g. google.com'
          onChange={(event) => handleUrlChange(event.target.value)}
          name='url'
          value={url}
          validators={['required', 'isUrl']}
          errorMessages={['This field is required', 'The url is not valid yet... keep typing']}
          margin='normal'
          variant='outlined'
          className={className}
          InputLabelProps={{
            shrink: true
          }}
          id={`startDownloadUrlInput-${index}`}
          itemProp='fromLocation'
        />
      )}
    </DownloadFormContext.Consumer>
  )
}

export default withStyles(styles)(DownloadUrlInput)
